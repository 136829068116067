import { React, Component } from "react"
import { Row } from "reactstrap"
import { FiMoon, FiSun } from "react-icons/fi"
import Menu from "components/menu"
import Switch from "react-switch";

export default class Header extends Component {
    render() {
        const {
            toggleThemeChange,
            isDarkMode
        } = this.props
        return (
            <Row className="header">
                <Row className="header-theme">
                    <Switch
                        className="header-theme-switch"
                        onChange={toggleThemeChange}
                        checked={isDarkMode}
                        onColor="#545353"
                        offColor="#a5ecf7"
                        onHandleColor="#d8d8d8"
                        offHandleColor="#f6f6f6"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        handleDiameter={30}
                        uncheckedHandleIcon={(<FiSun />)}
                        checkedHandleIcon={(<FiMoon />)}
                    />
                </Row>
                <Menu />
            </Row>
        )
    }
}