import Timeline from "components/timeline"
import { React, Component } from "react"
import { Container, Row } from "reactstrap"
import { getJobs } from "configs/jobs"
import { sortObject } from "utils/functions"
import { BsArrowReturnRight } from "react-icons/bs"

export default class CarrerPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            jobs: []
        }
    }

    componentDidMount() {
        this.setState({
            jobs: getJobs()
        })
    }

    getJobDescription(job) {
        return (
            <Row className="carrer-job">
                <Row className="txt-description">
                    {job.description}
                </Row>
                <Row className="carrer-job-method">
                    <Row className="card-title">
                        <BsArrowReturnRight />
                        Metodologia
                    </Row>
                    <Row className="card-items">
                        <span>
                            {job.methodology.icon}
                            <span>{job.methodology.name}</span>
                        </span>
                    </Row>
                </Row>
                <Row className="carrer-job-tech">
                    <Row className="card-title">
                        <BsArrowReturnRight />
                        Tecnologias
                    </Row>
                    <Row className="card-items-sm">
                        {job.technologies.sort((a, b) => sortObject(a, b, "name"))
                            .map((tech, i) => (
                                <span
                                    key={`tech-icon-${job.index}-${i}`}
                                >
                                    {tech.icon}
                                    <span>{tech.name}</span>
                                </span>
                            ))}
                    </Row>
                </Row>
            </Row>
        )
    }

    render() {
        const {
            jobs
        } = this.state
        return (
            <Container className="carrer">
                <Timeline events={jobs} getDescription={this.getJobDescription.bind(this)} />
            </Container>
        )
    }
}