import { React, Component } from "react"
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { getContacts } from "configs/contacts"
import { isValidateEmail, sortObject } from "utils/functions"
import { FaExternalLinkAlt } from "react-icons/fa"
import { toast } from "react-toastify"
import { sendEmail } from "services/mail"
import Loader from "react-loader-spinner"

const defaultForm = {
    name: "",
    email: "",
    message: ""
}

export default class ContactPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            form: defaultForm,
            isLoading: false,
            contacts: [],
            gamings: []
        }
    }

    componentDidMount() {
        let data = getContacts()
        this.setState({
            contacts: data.contacts,
            gamings: data.gaming
        })
    }

    toggleLoading() {
        let state = this.state
        state.isLoading = !state.isLoading
        this.setState(state)
    }

    handleOnClick(event, hasLink) {
        if (!hasLink)
            event.preventDefault()

    }

    handleInputChange({ target }) {
        this.setState({
            form: {
                ...this.state.form,
                [target.name]: target.value
            }
        })
    }

    handleButtonClick() {
        if (!this.state.isLoading) {
            const body = {
                name: this.state.form.name,
                email: this.state.form.email,
                message: this.state.form.message
            }

            if (!body.name || !body.email || !body.message)
                return toast('Todos os campos são obrigatórios... 😅');

            if (!isValidateEmail(body.email))
                return toast('Utilize um email válido para que eu possa te retornar... 😉');

            this.toggleLoading()
            sendEmail(body)
                .then(() => {
                    const form = defaultForm
                    this.setState({ form })
                    toast("Mensagem enviada, logo entrarei em contato! 😄")
                })
                .catch(e => {
                    console.log(e)
                    toast.error("Não foi possível enviar a mensagem... 😳")
                })
                .finally(() => {
                    this.toggleLoading()
                })
        }
    }

    _buildContacts(label, data) {
        return (
            <Row className="contacts-info-section">
                <Row>
                    <span className="card-title-bold">{label}</span>
                </Row>
                <Row className="card-items">
                    {
                        data.sort((a, b) => sortObject(a, b, "type"))
                            .map((item, i) => (
                                <a
                                    key={`item-icon-${item.type}-${i}`}
                                    href={item.link}
                                    target={!item.hasLink ? "" : "_blank"}
                                    onClick={(e) => this.handleOnClick(e, item.hasLink)}
                                    rel="noreferrer"
                                >
                                    <span>
                                        {item.icon}
                                        {item.label}
                                    </span>
                                    {
                                        !item.hasLink ? "" :
                                            <FaExternalLinkAlt style={{ fontSize: ".8em", marginLeft: "5px" }} />
                                    }
                                </a>
                            ))
                    }
                </Row>
            </Row>
        )
    }

    render() {
        const {
            contacts,
            gamings,
            form,
            isLoading
        } = this.state
        return (
            <Container className="contacts">
                <Col className="contacts-info">
                    {
                        this._buildContacts("Contatos", contacts)
                    }
                    {
                        this._buildContacts("Gaming", gamings)
                    }
                </Col>
                <Col className="contacts-message card">
                    <span className="contacts-message-title">
                        Entre em contato :)
                    </span>
                    <Form className="contacts-message-form">
                        <FormGroup className="input-group">
                            <Label
                                className="input-label"
                                for="name"
                            >
                                Nome
                            </Label>
                            <Input
                                className="input-txt"
                                type="text"
                                name="name"
                                id="name"
                                value={form.name}
                                onChange={this.handleInputChange.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Label
                                className="input-label"
                                for="email"
                            >
                                Email
                            </Label>
                            <Input
                                className="input-txt"
                                type="email"
                                name="email"
                                id="email"
                                value={form.email}
                                onChange={this.handleInputChange.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Label
                                className="input-label"
                                for="message"
                            >
                                Mensagem
                            </Label>
                            <Input
                                className="input-txt-area"
                                type="textarea"
                                name="message"
                                id="message"
                                value={form.message}
                                onChange={this.handleInputChange.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Button
                                className="input-button"
                                disabled={isLoading}
                                onClick={this.handleButtonClick.bind(this)}
                            >
                                {isLoading ? (<Loader
                                    type="ThreeDots"
                                    color="#FFFFFF"
                                    height={40}
                                    width={40}
                                />) : 'Enviar Mensagem'}
                            </Button>
                        </FormGroup>

                    </Form>
                </Col>
            </Container>
        )
    }
}