import { React, Component } from "react"
import { Row } from "reactstrap"
import { AiOutlineCopyright } from "react-icons/ai"

export default class Footer extends Component {
    render() {
        return (
            <Row className="footer">
                <span>
                    <AiOutlineCopyright />
                    {"  Marco Antonio Paiva, 2021."}
                </span>
            </Row>
        )
    }
}