import { GoHome } from "react-icons/go"
import { BsCodeSlash } from "react-icons/bs"
import { BsPersonBoundingBox } from "react-icons/bs"
import { AiOutlineMessage } from "react-icons/ai"

export const getMenus = () => {
    return [
        {
            id: 0,
            label: "Início",
            path: "/",
            icon: <GoHome />
        },
        {
            id: 1,
            label: "Carreira",
            path: "/carrer",
            icon: <BsCodeSlash />
        },
        {
            id: 2,
            label: "Sobre",
            path: "/about",
            icon: <BsPersonBoundingBox />
        },
        {
            id: 3,
            label: "Contato",
            path: "/contact",
            icon: <AiOutlineMessage />
        }
    ];
}