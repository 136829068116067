import {
    AiOutlineInstagram,
    AiOutlineLinkedin,
} from "react-icons/ai"
import {
    SiDiscord,
    SiGmail,
    SiPlaystation,
    SiSteam,
    SiXbox
} from "react-icons/si"

export const getContacts = () => {
    return {
        info: "",
        contacts: [
            {
                type: "instagram",
                label: "@marcopaivaa",
                icon: <AiOutlineInstagram />,
                link: "https://www.instagram.com/marcopaivaa/",
                hasLink: true
            },
            {
                type: "linkedin",
                label: "in/marcopaivaa",
                icon: <AiOutlineLinkedin />,
                link: "https://www.linkedin.com/in/marcopaivaa/",
                hasLink: true
            },
            {
                type: "email",
                label: "marcopaivaa@gmail.com",
                icon: <SiGmail />,
                link: "mailto:marcopaivaa@gmail.com",
                hasLink: true
            },
            {
                type: "discord",
                label: "marcopaivaa#9341",
                icon: <SiDiscord />,
                link: "https://discordapp.com/users/marcopaivaa#9341/",
                hasLink: true
            },
        ],
        gaming: [
            {
                type: "psn",
                label: "marcopaivaa",
                icon: <SiPlaystation />,
                link: "",
                hasLink: false
            },
            {
                type: "steam",
                label: "marcopaivaa",
                icon: <SiSteam />,
                link: "https://steamcommunity.com/id/marcopaivaa",
                hasLink: true
            },
            {
                type: "xbox",
                label: "marcopaivaa",
                icon: <SiXbox />,
                link: "",
                hasLink: false
            },

        ]
    }

}