import { DiScrum } from "react-icons/di"
import { BsKanban } from "react-icons/bs"
import {
    ANGULAR,
    ANGULARJS,
    AWS_COGNITO,
    AWS_LAMBDAS,
    AZURE,
    AZURE_DEVOPS,
    AZURE_FUNCTIONS,
    AZURE_PIPELINES,
    CODEIGNITER,
    C,
    CSHARP,
    CSS,
    DOCKER,
    DOTNET,
    IONIC,
    MONGODB,
    MYSQL,
    NODEJS,
    PHP,
    REACT,
    SQLSERVER
} from './technologies';

export const getJobs = () => {
    return [
        {
            index: 1,
            date: "AGO/2017",
            title: "WI Digital",
            subtitle: "Desenvolvedor PHP",
            titleLink: "https://www.widigital.com.br/",
            description: `
                A WI Digital é uma empresa de tecnologia e consultoria especializada no mercado de vendas diretas, marketing multinível e afiliados.
                Foi minha primeira experiência na área de desenvolvimento, onde tive a oportunidade de 
                realizar manutenção de código, desenvolvimento de novas features, 
                emissão de relatórios do banco de dados, integração de API’s e, por fim, atuar como PO e Team Lead, 
                levantando as necessidades do cliente e liderando o desenvolvimento.
            `,
            logo: "wi-logo.jfif",
            color: "#0095cd",
            textColor: "#fefefe",
            technologies: [
                PHP,
                CODEIGNITER,
                C,
                NODEJS,
                CSS,
                MYSQL,
                MONGODB,
                DOCKER,
            ],
            methodology: {
                name: "Kanban",
                icon: <BsKanban />
            }
        },
        {
            index: 2,
            date: "AGO/2019",
            title: "Cadmus Soluções em TI",
            subtitle: "Analista Desenvolvedor",
            titleLink: "https://cadmus.com.br/",
            description: `
                A Cadmus é uma empresa de consultoria em tecnologia, focada na entrega de diferentes tipo de projetos com
                metodologias ágeis.
                Aqui atuei com o desenvolvimento de um projeto específico do zero até a entrega de seu primeiro MVP,
                participando de reuniões de planejamento e estimativas. Em paralelo, realizava também a manutenção e 
                suporte para outros projetos do time.
            `,
            logo: "cadmus-logo.jfif",
            color: "#34d330",
            textColor: "#051b2c",
            technologies: [
                CSHARP,
                DOTNET,
                ANGULAR,
                ANGULARJS,
                IONIC,
                PHP,
                SQLSERVER,
                CSS
            ],
            methodology: {
                name: "Scrum",
                icon: <DiScrum />
            }
        },
        {
            index: 3,
            date: "JAN/2020",
            title: "Premmiar",
            subtitle: "Analista Desenvolvedor",
            titleLink: "https://premmiar.com.br/",
            description: `
                A Premmiar é uma startup que nasceu com o objetivo de criar uma relação mutuamente benéfica
                entre parceiros e clientes, através de uma plataforma personalizável e autogerenciável para
                criação de campanhas de incentivo e fidelidade.
                Aqui atuei no desenvolvimento da plataforma na sua fase inicial até seus testes betas com os 
                clientes, trabalhando em diferentes módulos e 
                API's que consistiam na solução principal do produto. O ecossistema era componsto por diferentes
                aplicações e arquiteturas, tais como microsserviços, micro frontends e CQRS Pattern. Durante essa
                fase, obtive um grande conhecimento com as soluções Azure da Microsoft, desde DevOps até Cloud Computing.
            `,
            logo: "premmiar-logo.jpg",
            color: "#009dff",
            textColor: "#fefefe",
            technologies: [
                CSHARP,
                DOTNET,
                NODEJS,
                REACT,
                SQLSERVER,
                MONGODB,
                CSS,
                AZURE,
                AZURE_FUNCTIONS,
                AZURE_PIPELINES,
                AZURE_DEVOPS,
                AWS_COGNITO,
                AWS_LAMBDAS,
            ],
            methodology: {
                name: "Scrum",
                icon: <DiScrum />
            }
        },
        {
            index: 4,
            date: "ABR/2021",
            title: "Ambev Tech",
            subtitle: "Analista Desenvolvedor",
            titleLink: "https://ambevtech.com.br/",
            description: `
                A Ambev Tech é o hub de tecnologia da Ambev, responsável por criar as soluções da empresa,
                visando sempre se reiventar e inovar para transformar as áreas de vendas e logística da 
                maior cervejaria do país. Aqui atuo no time responsável pelas ferramentas de negociação de
                frete, com aplicações mobile e web. Com total autonomia, exerço atividades de desenvolvimento,
                code review e devops, no ecossistema da Azure.
            `,
            logo: "ambev-logo.jpg",
            color: "#ffc72d",
            textColor: "#2c2a26",
            technologies: [
                CSHARP,
                DOTNET,
                REACT,
                ANGULAR,
                AZURE,
                AZURE_PIPELINES,
                AZURE_DEVOPS,
                SQLSERVER,
                IONIC
            ],
            methodology: {
                name: "Scrum",
                icon: <DiScrum />
            }
        },
    ];
}