import { React, Component } from "react"
import { Col, Container, Row } from "reactstrap"
import { sortObjectDesc } from "utils/functions"
import { FaExternalLinkAlt } from "react-icons/fa"

export default class Timeline extends Component {
    render() {
        const {
            events,
            getDescription
        } = this.props
        return (
            <Container className="timeline">
                {
                    events.sort((a, b) => sortObjectDesc(a, b, "index"))
                        .map((event, i) => (
                            <Row
                                key={`timeline-item-${i}`}
                                className="timeline-event"
                            >
                                <Col className="timeline-event-logo_container">
                                    <img
                                        className="timeline-event-logo"
                                        src={`${event.logo}`}
                                        alt={`logo-${event.title}`}
                                        style={{ borderColor: event.color }}
                                    />
                                </Col>
                                <Col
                                    className="timeline-event-date"
                                    style={{
                                        background: event.color,
                                        color: event.textColor
                                    }}
                                >
                                    {event.date}
                                </Col>
                                <Col className="timeline-event-content card">
                                    <Row className="timeline-event-title">
                                        <a
                                            href={event.titleLink}
                                            target="_blank"
                                            rel="noreferrer"

                                            style={{ color: event.color }}
                                        >
                                            <span>{event.title}</span>
                                            <FaExternalLinkAlt />
                                        </a>
                                    </Row>
                                    <Row
                                        className="timeline-event-subtitle"
                                        style={{ color: event.color }}
                                    >
                                        {event.subtitle}
                                    </Row>
                                    <Row className="timeline-event-description">
                                        {getDescription(event)}
                                    </Row>
                                </Col>
                            </Row>
                        ))
                }
            </Container>
        )
    }
}