import { React, Component } from "react"
import { Container } from "reactstrap"
import Header from 'components/header'
import Footer from 'components/footer'
import AboutPage from "pages/about"
import CarrerPage from "pages/carrer"
import ContactPage from "pages/contact"
import HomePage from "pages/home"
import { getCookies, setCookies } from "utils/cookies"
import { Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

export default class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isDarkMode: false,
        }
    }

    componentDidMount() {
        this.toggleThemeChange(getCookies("isDarkMode") ?? false)
    }



    toggleThemeChange(isDarkMode = undefined) {
        if (isDarkMode === undefined)
            isDarkMode = !this.state.isDarkMode

        document.documentElement.className = "";
        document.documentElement.classList
            .add(`theme-${isDarkMode ? `dark` : "light"}`)

        this.setState({ isDarkMode })
        setCookies("isDarkMode", isDarkMode)
    }


    render() {
        const {
            isDarkMode
        } = this.state
        return (
            <Container>
                <Header
                    isDarkMode={isDarkMode}
                    toggleThemeChange={this.toggleThemeChange.bind(this)}
                />
                <Container className="content">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        newestOnTop
                        className="toast"
                    />
                    <Route path="/" exact component={HomePage} />
                    <Route path="/carrer" component={CarrerPage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/contact" component={ContactPage} />
                </Container>
                <Footer />
            </Container>
        )
    }
}