import { React, Component } from "react"
import { Nav, NavItem, Row } from "reactstrap"
import { getMenus } from "configs/menus"
import { Link } from "react-router-dom";

export default class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientWidth: document.body.clientWidth,
            selectedMenu: 0,
            menus: []
        }
    }

    componentDidMount() {
        this.handleResize()
        this.handleScroll()

        window.addEventListener('resize', this.handleResize.bind(this))
        window.onscroll = this.handleScroll.bind(this)

        let menus = getMenus()
        let path = window.location.pathname
        let selectedMenu = menus.find(menu => menu.path === path)

        this.setState({
            menus: menus,
            selectedMenu: selectedMenu?.id ?? menus[0].id
        })
    }

    handleSelectMenu(id) {
        this.setState({ selectedMenu: id })
    }

    handleResize() {
        this.setState({
            pageWidth: document.body.clientWidth
        })
    }

    handleScroll() {
        let scrollPos = window.pageYOffset
        let sideMenu = document.getElementById("side-menu")
        if (sideMenu) {
            sideMenu.hidden = scrollPos < 90 ? true : false
        }
    }

    _buildMenu(iconsAlign = "left") {
        const {
            menus,
            selectedMenu,
        } = this.state
        return (
            <Nav className="menu">
                {
                    menus.map((menu, i) => (
                        <NavItem className="menu-item" key={`menuitem-${i}`}>
                            <Link key={`menulink-${i}`}
                                to={menu.path}
                                className={menu.id === selectedMenu ? "menu-item-selected" : ""}
                                onClick={() => this.handleSelectMenu(menu.id)}
                            >
                                {iconsAlign === "left" ? menu.icon : ""}
                                {menu.label}
                                {iconsAlign === "right" ? menu.icon : ""}
                            </Link>
                        </NavItem>
                    ))
                }
            </Nav>
        )
    }

    render() {
        const {
            pageWidth
        } = this.state
        return (
            <span>
                {
                    this._buildMenu()
                }
                {
                    pageWidth <= 1200 ? "" :
                        <Row id="side-menu" className="menu-side">
                            {
                                this._buildMenu("right")
                            }
                        </Row>
                }
            </span>
        )
    }
}