import Cookies from 'universal-cookie';
import "utils/dateExtensions"

export const getCookies = (name) => {
    let cookies = new Cookies()
    return cookies.get(name, { doNotParse: false })
}

export const setCookies = (name, cookie) => {
    let cookies = new Cookies()
    return cookies.set(name, cookie, {
        path: "/",
        maxAge: 604800,
        expires: new Date().addDays(7),
    })
}