import { React, Component } from "react"
import { Container } from "reactstrap"

export default class HomePage extends Component {
    render() {
        return (
            <Container className="home">
                <div className="wip-div">
                    <img
                        className="wip-image"
                        src={`wip.png`}
                        alt={`wip`}
                    />
                </div>
            </Container>
        )
    }
}