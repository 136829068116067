export const sortObjectDesc = (a, b, field) => {
    if (a[field] > b[field]) return -1
    if (a[field] < b[field]) return 1
    return 0
}

export const sortObject = (a, b, field) => {
    if (a[field] < b[field]) return -1
    if (a[field] > b[field]) return 1
    return 0
}

export const isValidateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}