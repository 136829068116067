import {
    SiAmazonaws,
    SiAngular,
    SiAngularjs,
    SiAzuredevops,
    SiAzurefunctions,
    SiAzurepipelines,
    SiC,
    SiCodeigniter,
    SiCsharp,
    SiCss3,
    SiDocker,
    SiDotNet,
    SiIonic,
    SiMicrosoftazure,
    SiMicrosoftsqlserver,
    SiMongodb,
    SiMysql,
    SiNodeDotJs,
    SiPhp,
    SiReact
} from "react-icons/si"

export const PHP = {
    name: "PHP",
    icon: <SiPhp />
}

export const CODEIGNITER = {
    name: "CodeIgniter",
    icon: <SiCodeigniter />
}

export const C = {
    name: "Linguagem C",
    icon: <SiC />
}

export const NODEJS = {
    name: "NodeJs",
    icon: <SiNodeDotJs />
}

export const CSS = {
    name: "CSS",
    icon: <SiCss3 />
}

export const MYSQL = {
    name: "MySQL",
    icon: <SiMysql />
}

export const MONGODB = {
    name: "MongoDB",
    icon: <SiMongodb />
}

export const DOCKER = {
    name: "Docker",
    icon: <SiDocker />
}

export const CSHARP = {
    name: "C#",
    icon: <SiCsharp />
}

export const DOTNET = {
    name: ".NET Core",
    icon: <SiDotNet />
}

export const ANGULAR = {
    name: "Angular",
    icon: <SiAngular />
}

export const ANGULARJS = {
    name: "AngularJS",
    icon: <SiAngularjs />
}

export const IONIC = {
    name: "Ionic",
    icon: <SiIonic />
}

export const SQLSERVER = {
    name: "SQLServer",
    icon: <SiMicrosoftsqlserver />
}

export const REACT = {
    name: "React",
    icon: <SiReact />
}

export const AZURE = {
    name: "Azure",
    icon: <SiMicrosoftazure />
}

export const AZURE_FUNCTIONS = {
    name: "Azure Functions",
    icon: <SiAzurefunctions />
}

export const AZURE_PIPELINES = {
    name: "Azure Pipelines",
    icon: <SiAzurepipelines />
}

export const AZURE_DEVOPS = {
    name: "Azure DevOps",
    icon: <SiAzuredevops />
}

export const AWS_LAMBDAS = {
    name: "AWS Lambdas",
    icon: <SiAmazonaws />
}

export const AWS_COGNITO = {
    name: "AWS Cognito",
    icon: <SiAmazonaws />
}